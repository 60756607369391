import React, { useState } from 'react';

const SpecialityCard = ({ speciality, description }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div className='speciality-card'>
            <div className="speciality-card-front">
                <h3>{speciality}</h3>
                <div className='speciality-card-info'>
                    <p>{expanded ? description : `${description.slice(0, 80)}...`}</p>
                    <button onClick={toggleExpand} className="expand-button">
                        {expanded ? 'Ver Menos' : 'Saiba Mais'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SpecialityCard;
