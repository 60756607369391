import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit'; // MDB React UI Kit components

import Navbar from '../component/navbar'; // Component imports
import Footer from '../component/footer';
import Faq from '../component/faq';
import Ferramentas from '../component/ferramentas';

import { useEffect, useState } from 'react'; // React imports
import axios from 'axios'; // Axios for HTTP requests

import AOS from 'aos'; // Animation library import
import 'aos/dist/aos.css';
import 'swiper/css';
import { useMediaQuery } from 'react-responsive'; // Media query import

// Image imports
import heroImg from '../img/image 6.png';
import LogoHero from '../img/logoHero.svg';
import graphism from '../img/LogoW.png';
import marcelotext from '../img/person.png';
import Logo from '../img/LogoW.png';

// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/effect-coverflow';

// Component imports for cards
import SpecialityCard from '../component/specialityCard';
import FeedbackCard from '../component/feedbackCard';
import ServicesCard from '../component/servicesCard';

// Icon imports
import { FaWhatsapp } from "react-icons/fa";
import { IoAlertCircleOutline, IoBriefcaseOutline, IoBusinessOutline } from 'react-icons/io5';
import { BiBuildingHouse, BiMoney } from 'react-icons/bi';
import { TbGavel, TbBusinessplan } from 'react-icons/tb';
import { FaFileContract, FaShieldAlt } from 'react-icons/fa';
import { RiSecurePaymentLine, RiTeamLine } from 'react-icons/ri';

// Main Index Component
function Index() {
    useEffect(() => {
        document.title = "Marcelo Tomé | Advogado"; // Set page title
        window.scrollTo(0, 0); // Scroll to top on load
        AOS.init(); // Initialize AOS for animations
    });

    const [videoUrl, setVideoUrl] = useState('https://www.youtube.com/embed/hqDZpeXaYiE?si=bt8pz0s1nymqFyhb'); // Video URL state
    const [posts, setPosts] = useState([]); // State to hold blog posts

    // Media query hooks
    const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 769 });

    useEffect(() => {
        // Fetch blog posts from WordPress
        axios.get('https://blog.marcelotomeadv.com.br/wp-json/wp/v2/posts?categories=4')
            .then(response => {
                setPosts(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the blog posts!", error);
            });
    }, []);

    return (
        <div>
            {/* Navbar Component */}
            <Navbar />
            {/* Hero Section */}
            <MDBContainer data-aos="fade-down" data-aos-offset="150" fluid className='hero-container'>
                <MDBRow>
                    <MDBCol data-aos="flip-up" data-aos-offset="50" md={6} className='hero-left'>
                        <MDBRow>
                            <img className='imgtext' src={LogoHero} alt="Marcelo Text" style={{ width: '50%', marginLeft:'20%', marginBottom: '1rem' }} />
                        </MDBRow>
                        <MDBRow className='hero-left-description'>
                            <MDBCol data-aos="flip-up" data-aos-offset="50" md={12} sm={9}>
                                <p className='hero-glass'>
                                    Comprometido com soluções jurídicas eficientes e em condições facilitadas em São Paulo, nas regiões de Guarulhos, da Capital, do Alto Tietê e ABCD.
                                </p>
                            </MDBCol>
                            <MDBCol data-aos="flip-up" data-aos-offset="50" sd={6}>
                                <MDBBtn href='/#atuacao' className='hero-glass-atuation-celphone'>Conhecer Mais</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={6} className='hero-right'>
                        <img src={heroImg} alt="Hero" />
                        {/* <img className='imggraphism' src={graphism} alt="Graphism" /> */}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            {/* Speciality Section */}
            <MDBContainer id='especialidades' className='speciality-container'>
                <MDBContainer data-aos="flip-up" data-aos-offset="150">
                    <MDBRow>
                        <MDBCol md={3} className='speciality-text'>
                            <h4 className='titulos-azul'>Especialidade</h4>
                            <p>Atuo em diversas áreas do direito, encontrando soluções jurídicas judiciais e extrajudiciais. Pensão Alimentícia, Consumidor Bancário e Assessoria Jurídica para empresas são as mais recorrentes, mais de 80% das demandas. Conheça!
                            </p>
                        </MDBCol>
                        <MDBCol md={9}>
                            <Swiper
                                className='slide-items-clients'
                                modules={[Pagination, Autoplay]}
                                autoplay={{ delay: 2500 }}
                                navigation
                                pagination={{ clickable: true }}
                                spaceBetween={50}
                                onSwiper={(swiper) => console.log(swiper)}
                                loop={true}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 50,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <SpecialityCard
                                        speciality="PENSÃO ALIMENTÍCIA:"
                                        description="Seu filho tem direito a pensão alimentícia, não lutar por esse direito é negligência. Não caia nessa armadilha, lute pelo seu filho, entre em contato e descubra que é menos complicado do que você pensa."
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <SpecialityCard
                                        speciality="GOLPES E FRAUDES"
                                        description="Foi vítima de golpe ou fraude? Nesses casos é possível reaver os valores perdidos, cancelar empréstimos e compras no cartão de crédito, fazer estornos e muito mais. Para não pagar essa conta, entre em contato."
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <a href="#para-empresas-e-empreendedores" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <SpecialityCard
                                            speciality="ASSESSORIA JURÍDICA"
                                            description="Você é empresário ou empreendedor e não tem suporte jurídico para o seu negócio? Não corra mais riscos, a única coisa que pode te salvar de um mau negócio é um bom advogado, saiba mais."
                                        />
                                    </a>
                                </SwiperSlide>
                            </Swiper>
                        </MDBCol>

                    </MDBRow>
                </MDBContainer>
            </MDBContainer>

            {/* Work Areas Section */}
            <MDBContainer fluid id='atuacao' className='work-areas-container'>
                <MDBRow data-aos="flip-up" data-aos-offset="150" className='work-areas-title'>
                    <MDBCol>
                        <h4 className='titulos'>Áreas de Atuação</h4>
                    </MDBCol>
                    <MDBCol style={{ borderLeft: '1px solid #6B8BA4' }}>
                        <p>Além das especialidades, sendo as causas mais recorrentes, atuo em diversas áreas do direito de forma ampla ou correlata, prestando serviços avulsos ou mensais, dependendo da sua necessidade. Confira.
                        </p>
                    </MDBCol>
                </MDBRow>
                
                <MDBRow className='services-cards-holder' id="para-empresas-e-empreendedores">
                    <MDBRow className='services-cards'>
                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<TbGavel />}
                                service="PENSÃO ATRASADA:"
                                description="A pensão atrasou? Cobre antes que seja tarde demais! Trabalho cobrando com bloqueio de contas bancárias e penhora de bens (carro, moto, etc.) e também com a prisão. Se não pagar é preso! Entre em contato, é simples!"
                            />
                        </MDBCol>
                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<BiBuildingHouse />}
                                service="GUARDA E VISITAS:"
                                description="Conviver com o filho é um direito dos pais e dos filhos, mas “não é bagunça não”, se os pais não chegarem a um acordo o juiz determinará como será. Garanta que seja do seu jeito, da melhor forma para você e seu filho."
                            />
                        </MDBCol>
                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<RiSecurePaymentLine />}
                                service="MEDIDA PROTETIVA:"
                                description="Não seja refém de seu companheiro, reaja! É possível obter uma medida protetiva garantindo sua segurança tomando todas as medidas para se manter segura e viva. Entre em contato, dê o primeiro passo mudar de vida."
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='services-cards'>

                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<IoBriefcaseOutline />}
                                service="DIVÓRCIO E SEPARAÇÃO:"
                                description="Decidiu seguir em frente? Faço divórcio e dissolução de união estável, judicial e extrajudicial, mas atenção, dar o primeiro passo e assumir a ofensiva é fundamental para defender seus interesses, não perca tempo, entre em contato!"
                            />
                        </MDBCol>
                        
                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<FaFileContract />}
                                service="CONTRATAÇÕES INDEVIDAS"
                                description="Já deu uma olhada no seu extrato bancário hoje? Descontaram valores que você não autorizou? Pois é, você foi vítima de contatações indevidas! Não perca esse dinheiro, é mais simples do que você imagina."
                            />
                        </MDBCol>
                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<FaShieldAlt />}
                                service="BUSCA E APREENSÃO:"
                                description="Está devendo prestação de carro? O banco pode apreendê-lo a qualquer momento, mas para isso deve respeitar os direitos do consumidor, senão a busca e apreensão é ilegal. Entre em contato para análise de seu caso."
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='services-cards'>

                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<TbGavel />}
                                service="LEILÕES JUDICIAIS:"
                                description="Você sabia que é possível comprar imóveis em perfeito estado, seminovos, em todo o país, por preços que podem chegar a 50% do valor de mercado? Mas para ser um bom negócio é melhor analisar cada caso para não entra numa fria."
                            />
                        </MDBCol>
                        
                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<RiTeamLine />}
                                service="REVISIONAIS DE CONTRATOS:"
                                description="Existem vários tipos de contratos de crédito que cobram juros, taxas e encargos ilegais, para combater esta prática é possível revisar o contrato e reaver o dinheiro corrigido com juros e atualizações monetárias. Entre em contato e descubra."
                            />
                        </MDBCol>
                        <MDBCol data-aos="flip-up" data-aos-offset="150" md={4}>
                            <ServicesCard
                                icon={<IoBusinessOutline />}
                                service="PARA EMPRESAS E EMPREENDEDORES"
                                description="Se você é empresário ou empreendedor e não tem suporte jurídico para seu negócio, saiba que está correndo um grande perigo, existem diversos riscos trabalhistas, tributários, contratuais, etc., que podem se tornar um passivo judicial se não forem bem orientados, isso vai causar prejuízo financeiro e a imagem da sua marca. Consulte as condições para realização de serviços avulsos ou contratações de assessoria mensal, tais como elaboração e gestão de contratos, defesas trabalhistas, regularizações tributárias, pesquisa de idoneidade, contencioso em geral. Consulte."
                            />
                        </MDBCol>
                    </MDBRow>

                </MDBRow>


            </MDBContainer>

            {/* Contact Section */}
            <MDBContainer id='contato' fluid className='contact-container'>
                <MDBContainer data-aos="flip-down" data-aos-offset="150">
                    <MDBRow>
                        <MDBCol md={5} className='contact-text' data-aos="fade-down" data-aos-offset="150">
                            <iframe
                                src={videoUrl}
                                className='iframe'
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Marcelo Tomé"
                            >   
                            </iframe>
                        </MDBCol>
                        <MDBCol md={1}>

                        </MDBCol>
                        <MDBCol md={6} className='contact-buttons'>
                            <h4 className='titulos-azul' >Entre em Contato!</h4>
                            <p>Nos ligue ou mande uma mensagem!</p>
                            <MDBBtn tag="a" href="https://wa.me/5511976150552" className='contact-wpp' target="_blank">
                                Whatsapp
                            </MDBBtn>

                            <p className='p-0 m-2'>Ou se preferir</p>
                            <MDBBtn tag="a" href="mailto:contato@marcelotomeadv.com.br" className='contact-mail'>
                                E-mail - contato@marcelotomeadv.com.br
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>

            {/* Blog Section */}
            <MDBContainer id='blog' className='blog-container'>
                <MDBContainer data-aos="flip-up" data-aos-offset="150">
                    <h4 className='mb-6 titulos'>Blog</h4>
                    <MDBRow className='d-flex justify-content-center align-itens-center'>
                        {posts.map(post => (
                            <MDBCol key={post.id} md={4} className='blog-post'>
                                <h2 className='mb-4'>{post.title.rendered}</h2>
                                <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                <MDBBtn href={post.link} target='_blank'>Leia mais</MDBBtn>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
            
            {/* FAQ Section */}
            <Faq />

            {/* Ferramentas  sessão*/}
            <Ferramentas />

            {/* Footer Section */}
            <Footer />

            {/* Botão Flutuante do WhatsApp */}
            <a href="https://wa.me/5511976150552" target="_blank" rel="noopener noreferrer" className="whatsapp-float">
                <FaWhatsapp />
            </a>
        </div >
    );
}

export default Index;
