import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import Logo from '../img/LogoW.png'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export default function App() {
    const [openBasic, setOpenBasic] = useState(false);

    return (
        <MDBContainer fluid className='footer-container' style={{ backgroundColor: '' }}>
            <MDBRow>
                <MDBCol md={3} className='footer-logo'>
                    <img src={Logo}></img>
                </MDBCol>
                <MDBCol md={3}>

                </MDBCol>
                <MDBCol md={3} className='footer-info'>
                    <h3>Entre em Contato</h3>
                    <p>Seu direito, simples, rápido e sob medida.</p>
                    <p>Email: contato@marcelotomeadv.com.br</p>
                </MDBCol>
                <MDBCol md={3} className='footer-links'>
                    <h3>Links Rápidos</h3>
                    <a href='/'>Inicio</a>
                    <a href='/#especialidades'>Especialidades</a>
                    <a href='/#atuacao'>Áreas de Atuação</a>
                    <a href='/#contato'>Contato</a>
                </MDBCol>
            </MDBRow>
            <MDBRow className='footer-copyright'>
                <MDBCol md={6}>
                    <p>Copyright © Marcelo Tomé 2024</p>
                </MDBCol>
                <MDBCol md={6} className='footer-socials'>
                    <p>Nos siga: </p>
                    <a href='https://www.facebook.com/TomeAdv/'><FaFacebookSquare /></a>
                    <a href='https://www.instagram.com/marcelotomeadvogado'><FaInstagramSquare /></a>
                    <a href='https://www.linkedin.com/in/marcelostome/'><FaLinkedin /></a>

                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}